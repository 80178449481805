function fixOnScroll() {
    // noinspection JSUnresolvedVariable
    ScrollTrigger.create({
        start: 'top -50',
        end: 99999,
        toggleClass: {className: 'fixOnScroll--scrolled', targets: '.fixOnScroll'}
    });
    // noinspection JSUnresolvedVariable
    ScrollTrigger.create({
        start: 'top -500',
        end: 99999,
        toggleClass: {className: 'fixOnScroll--hide', targets: '.fixOnScroll'},
        onUpdate: self => {
            let targets = document.getElementsByClassName('fixOnScroll')
            Array.prototype.forEach.call(targets, function (target) {
                if (self.direction === -1 && self.getVelocity() > -300) {
                    target.classList.remove('fixOnScroll--hide')
                }
                if (self.direction === 1 && self.getVelocity() > 300) {
                    target.classList.add('fixOnScroll--hide')
                }
            });
        }
    });
}

function toggleMobileNav() {
    const sidebarNav = document.getElementById("sidebar-nav");
    const toggleNavButton = document.getElementById("toggle-nav");

    const toggleClass = function () {
        sidebarNav.classList.toggle('is-open');
    };

    toggleNavButton.addEventListener('click', toggleClass);
    window.addEventListener('resize', function (){
        sidebarNav.classList.remove('is-open')
    })
}

function showSubmenu(event) {
    const self = this;
    const active = 'is-active';
    const submenu = document.getElementById(self.dataset.id);
    console.log("click")
    //event.preventDefault();

    Array.prototype.filter.call(
        submenu.parentNode.children,
        function (child) {
            if (child !== submenu) {
                removeChildClass(child);
            }
        });

    Array.prototype.filter.call(
        self.parentNode.children,
        function (child) {
            if (child !== self) {
                removeChildClass(child);
            }
        });
    self.classList.toggle(active);
    submenu.classList.toggle(active);
    setBodyClass();
}

function removeChildClass(el) {
    const active = 'is-active';
    if (el.classList.contains(active)) {
        el.classList.remove(active);
    }
}

function setBodyClass(){
    let count = document.getElementsByClassName('sub-nav is-active').length
    let body = document.getElementById('container');
    if(count > 0 ){
        body.classList.add('menu-open')
    }else {
        body.classList.remove('menu-open')
    }
}

function AnimateFirstPicture(){
    let element = document.getElementsByClassName('hero-block__figure zoomImage');
    if(element.length !== 0){
        setTimeout(function (){
            element[0].classList.add('zoom');
        }, 1000)

    }

}

document.addEventListener("DOMContentLoaded", function () {
    // noinspection JSUnresolvedVariable
    gsap.registerPlugin(ScrollTrigger);
    fixOnScroll();
    toggleMobileNav();
    setBodyClass()
    AnimateFirstPicture();
    const hasSubmenu = document.querySelectorAll('li[data-id]');
    let i;
    for (i = 0; i < hasSubmenu.length; i++) {
        hasSubmenu[i].addEventListener('click', showSubmenu);
    }
});

